import React from 'react';

import Container from 'react-bootstrap/esm/Container';
const Testimonial = (props) => {

  const {title, description, author} = props.data;

  return (
    <Container className="testimonial-slide text-center text-light my-auto mx-auto p-5">
      <h2>{title}</h2>
      <p className="testimonial-text">{description}</p>
      <h4>-{author}</h4>
    </Container>
  );
};

export default Testimonial;
