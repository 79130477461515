import Container from "react-bootstrap/esm/Container";
import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";


import headshot from '../../assets/images/headshot.png';
// import aboutBack from '../../assets/images/about-back.svg';
import wave1 from '../../assets/images/wave-1.svg';
import wave2 from '../../assets/images/wave-2.svg';


const About = () => {

  const { section } = useParams();
  useEffect(() => {
    if (section === 'about') {
      const homeElem = document.getElementById('about');
      homeElem.scrollIntoView();
    }

  }, [section]);

    return (
        <div className="bg-elem-container bg-about">
            <img src={wave1} alt="graphical element" className="bg-elem wave1"></img>
            <img src={wave2} alt="graphical element" className="bg-elem wave2"></img>

            <Container id="about" className="about-section text-primary mb-5">
                <div className="about-text">
                    <div className="about-header mx-2">
                        <h2>ABOUT ME</h2>
                        <h3>LICENSED REALTOR&#174;</h3>
                    </div>
                    <div className="about-description mt-5 mx-2">
                        {/* <h4>BACKGROUND</h4> */}
                        <div className="background-details">
                            Before entering into Real Estate, my previous professional sales experience of almost 10 years in Calgary where I had worked for some of the biggest and most admired Canadian companies like Walmart Canada Inc,  Rogers Telecommunications Inc and Reliance Home Comfort. I have driven for work on sales appointments in homes across Calgary and its satellite towns like Airdie, Cochrane, Chestermere, Okotoks, High River, Red Deer and Edmonton. During this, I have helped homeowners select their alarm system with smart home security and automations for their homes, cell phone plans, furnaces, air conditioners, water heaters,  heat pumps, water softeners and reverse osmosis water filters. 
                            <br></br>
                            <br></br>
                            These in-home assessments and sales activities to almost 400+ homes gave me vast knowledge about the city, various communities, type of homes, different features, layouts and its functionalities which I can leverage to help my clients in buying / selling and investment properties. On top of that, my Bachelor's degree in Engineering, Diploma in Business Administration and almost 25+ years of business experience will help my clients totally. 
                            <br></br>
                            <br></br>
                            Get in touch with me soon to meet in my office and I can walk you through all the options available matching your criteria to start your journey in Real Estate.
                            {/* <ul>
                                <li>
                                <span>Bachelor's Degree in </span>
                                <span>Engineering</span>
                                </li>

                                <li>
                                <span>Diploma in </span>
                                <span>Business Administration</span>
                                </li>

                                <li>
                                <span>2+ Decades</span>
                                <span> business experience in Mumbai, India</span>
                                </li>

                                <li>
                                <span>8+ Years</span>
                                <span> in Home Security, Telecom, HVAC, and Water Products</span>
                                </li>
                                
                                <li>
                                <span>Advised </span>
                                <span>4000+</span>
                                <span> Calgary Homes and Satellite Towns on the above</span>
                                </li>

                                <li>
                                <span>Empowering New Immigrants, Food Distribution & Blood Donation</span>
                                </li>
                                
                                <li>
                                <span>Nature Lover and Rock/Bollywood Music Fan</span>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>

                <div className="about-graphic">
                    <img src={headshot} alt="Profile of Mustafa Vora"></img>
                </div>
                

            </Container>
            {/* <img src={aboutBack} className="about-back" alt="Abstract shapes" loading="lazy"></img> */}

        </div>



    );
}
 
export default About;


