import Container from "react-bootstrap/esm/Container";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import contactBack from '../../assets/images/contact-back.svg';
import useStoreData from "../../hooks/useStoreData";

const Contact = () => {

    const { section } = useParams();
    useEffect(() => {
        if (section === 'contact') {
        const homeElem = document.getElementById('contact');
        homeElem.scrollIntoView(false);
        }

    }, [section]);

    const [ name, setName ] = useState("");
    const [ contactInfo, setContactInfo ] = useState("");
    const [ message, setMessage ] = useState("");

    const { addData, processing, error } = useStoreData('contact-form');
    const [ done, setDone ] = useState(false);


    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = {
            name: name,
            contact: contactInfo,
            message: message
        };

        console.log(`Sent a message: `, data);

        if (!processing) {
          await addData(data);

            if (!error) {
                // Reset form
                setName("");
                setContactInfo("");
                setMessage("");
    
                // Pop up showing that you submitted a form
                setDone(true);
                setTimeout(() => setDone(false), 10000);
            } else {
                console.log('Error in submitting form!');
            }
  
        } else {
          console.log('Form is processing. Wait to submit another response!');
        }
    };

    return ( 
        <div id="contact" className="bg-element-container contact-container">
            <Container className="contact mt-5">
                <div className="contact-box">
                    <img src={contactBack} alt="visual element"></img>
                    <h2>LET'S TALK!</h2>

                    <Form className="contact-form" onSubmit={handleSubmit}>
                        <Form.Group className="mb-3 name" controlId="formBasicEmail">
                            <InputGroup size='lg' className="mb-3   ">
                                <InputGroup.Text id="inputGroup-sizing-lg">
                                Name 
                                </InputGroup.Text>
                                <Form.Control
                                required
                                aria-label="Name"
                                aria-describedby="inputGroup-sizing-sm"
                                value={name}
                                onChange={({target:{value}}) => setName(value)}
                                />
                            </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3 contact-info" controlId="formBasicEmail">
                            <InputGroup size='lg' className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-lg">
                                Contact
                                </InputGroup.Text>
                                <Form.Control
                                required
                                aria-label="Contact"
                                aria-describedby="inputGroup-sizing-sm"
                                value={contactInfo}
                                onChange={({target:{value}}) => setContactInfo(value)}
                                />
                            </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <InputGroup size='lg' className="contact-message">
                                <InputGroup.Text id="inputGroup-sizing-lg">
                                Message
                                </InputGroup.Text>
                                <Form.Control 
                                required
                                className="contact-message"
                                as="textarea" 
                                aria-label="Message" 
                                aria-describedby="inputGroup-sizing-sm"
                                value={message}
                                onChange={({target:{value}}) => setMessage(value)}
                                />
                            </InputGroup>
                        </Form.Group>

                        <Button size="lg" variant="primary" type="submit" className="submit-button">
                            Submit
                        </Button>
                        { (done || processing) && 
                            <Alert variant={processing ? "warning" : "primary"} className="container mt-5">
                                {
                                   (processing && "Submitting...") ||
                                   (done && "Thank you for your inquiry! I will reach out to you as soon as possible!")
                                }
                            </Alert> 
                        }
                    </Form>
                </div>
            </Container>

        </div>
     );
}
 
export default Contact;