import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const ListingCard = (props) => {
    const { imgSrc, address, yearBuilt, size, specs, price, disabled } = props.data;
    return ( 

        // TODO: Turn entire card into a clickable that takes you to the listing page
        <div className='listing-card-container'>
            <Card className='listing-card bg-card text-white'>
            {
            !disabled &&   
                <>
                <div className='listing-card-img-container'>
                    <Card.Img variant="top" src={imgSrc} className='listing-card-img'/>
                </div>
                <Card.Body>
                    <Card.Title>{address}</Card.Title>
                    <Card.Text>
                        <div className='listing-card-details'>
                            

                            <div className='listing-card-details-row'>
                                <span>Year Built</span>
                                <span>{yearBuilt}</span>
                            </div>

                            <div className='listing-card-details-row'>
                                <span>Size</span>
                                <span>{size}</span>
                            </div>

                            <div className='listing-card-details-row'>
                                <span>Bed/Bath/Half</span>
                                <span>{specs}</span>
                            </div>

                            <div className='listing-card-details-row'>
                                <span>Price</span>
                                <span>{price}</span>
                            </div>
                            
                        <Button className='listing-card-button' variant="highlight">BOOK SHOWING</Button>
                        </div>
                    </Card.Text>
                </Card.Body>
                </>
            }

            {
            disabled &&
                <>
                <Card.Body>
                    <Card.Text>
                        <div className='listing-card-details' style={{minWidth: '15rem', minHeight: '20rem'}}>
                            <Card.Title style={{margin: 'auto', fontSize: '2rem'}}>Coming Soon!</Card.Title>
                        </div>
                    </Card.Text>
                </Card.Body>
                </>
            }
            </Card>
        </div>
     );
}
 
export default ListingCard;