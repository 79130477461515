import { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Testimonial from './Testimonial';
import { useParams } from "react-router-dom";

function TestimonialCarousel() {

  const { section } = useParams();
  useEffect(() => {
    if (section === 'testimonials') {
      const homeElem = document.getElementById('testimonials');
      homeElem.scrollIntoView(false);
    }

  }, [section]);

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const [testimonials, setTestimonials] = useState([]);

  useEffect(()=> {
    setTestimonials([
      {
        id: 1,
        title: "Client Testimonial #1",
        description: "Words cannot express our gratitude towards Mr. Vora for the immense amount of support he provided us when selling our home. He is knowledgeable, proactive, professional and ultimately a genuine, kind hearted human being who did everything he could to make the process smooth. We sat in the passenger seat and let him do the driving, taking care of everything and guiding us throughout every road that comes with selling and buying. He went above and beyond to find us the perfect home and perfect buyer for our house of over 17 years. In fact, without him we don’t think our house would have conditionally sold in 72 hours of being on the market. For his care, unwavering support and tireless effort - we are in awe and express utmost gratitude. Mr Vora is a pleasure to work with, and there is truly nobody else like him.",
        author: "Tahera Kapacee"
      },
      {
        id: 2,
        title: "Client Testimonial #2",
        description: "I cannot say enough about how pleased I am with my service representative Mustafa and the company. He spent a lot of time explaining all the options and about the equipment to us, as we had a lot of questions. He was extremely knowledgeable, personable and professional. He offered greate advice for what would work best for our home and provide the best value. He followed up and we were able to meet and strike a deal in a short time. The installation was within a week and they were also excellent. We couldn't be more pleased. Thank you.\nPositive: Professionalism, Punctuality, Quality, Responsiveness, Value.",
        author: "Kristine Biegler"
      },
      {
        id: 3,
        title: "Client Testimonial #3",
        description: "Mustafa was really wonderful in getting us the right home. He resolved all issues very quickly also helped us get the house according to our choice. Thanks Mustafa, for finding us the perfect home.",
        author: "Sujetri Holla"
      },
      {
        id: 4,
        title: "Client Testimonial #4",
        description: "Reliance Team really did a good job. When I was first talking with Mr. Vora inregards to replace my hot water tank, immediately he gave me the best option to choose from. Iwent with rental, and Mr. Vora did set up installation promptly as it required quick replacement of my old corroded tank to avoid any further incident. The next day, Steve came and installed a new hot water tank. I am very happy about Reliance and their team. I would give them 10/10. Positive: Professionalism, Punctuality, Quality, Responsiveness, Value.",
        author: "Shamim M"
      },
    ]);
  }, []);

  return (
    <Carousel id="testimonials" activeIndex={index} onSelect={handleSelect} className='testimonial-carousel bg-dark'>
      {
        testimonials.map((testimonial) => {
          return <Carousel.Item key={testimonial.id}>
            <Testimonial data={testimonial}/>
          </Carousel.Item>
        })
      }
    </Carousel>
  );
}

export default TestimonialCarousel;