import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/esm/Container";

// import ReactCardFlip from 'react-card-flip';

import bgElem3 from '../../assets/images/bg-elem-3.svg';
import flowchart from '../../assets/images/flowchart.svg';

const BuySell = () => {

    const { section } = useParams();
    useEffect(() => {
      if (section === 'services') {
        const homeElem = document.getElementById('services');
        homeElem.scrollIntoView(true);
      }
  
    }, [section]);

    // const [ isBuyCardFlipped, setIsBuyCardFlipped ] = useState(false);
    // const [ isSellCardFlipped, setIsSellCardFlipped ] = useState(false);

    // const flipBuyCard = () => {
    //     setIsBuyCardFlipped(!isBuyCardFlipped);
    // }

    // const flipSellCard = () => {
    //     setIsSellCardFlipped(!isSellCardFlipped);
    // }

    return ( 
        <>
        <div id="services" style={{paddingTop: '10vh'}}></div>
        <div className="services-container bg-elem-container">
        <img src={bgElem3} alt="graphical element" className="bg-elem bg-elem-3"></img>
        <img src={bgElem3} alt="graphical element" className="bg-elem bg-elem-3-1"></img>


        <Container className="buy-sell-section container-xxl p-5">
            <h2>HOW TO GET STARTED?</h2>

            <div>
                <img src={flowchart} className="flowchart container-xl" alt="Flowchart outlining a the process of buying/selling homes."></img>
            </div>
            {/* <div className="card-container">
                <ReactCardFlip isFlipped={isBuyCardFlipped} flipDirection="horizontal">
                    <div className="info-card info-card-buy" onClick={flipBuyCard}>
                        <h3>BUY.</h3>
                    </div>

                    <div className="info-card info-card-back " onClick={flipBuyCard}>
                        <h3>flipped buy card.</h3>
                    </div>
                </ReactCardFlip>
            </div>

            <div className="card-container">
                <ReactCardFlip isFlipped={isSellCardFlipped} flipDirection="horizontal">
                    <div className="info-card info-card-sell" onClick={flipSellCard}>
                        <h3>SELL.</h3>
                    </div>

                    <div className="info-card info-card-back " onClick={flipSellCard}>
                        <h3>flipped sell card.</h3>
                    </div>
                </ReactCardFlip>

            </div> */}
        </Container>
        </div>
        </>
     );
}
 
export default BuySell;