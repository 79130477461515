import React from 'react';
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import phoneIcon from "../../assets/images/phone_icon.png";
import emailIcon from "../../assets/images/email_icon.png";
import addressIcon from "../../assets/images/address_icon.png";
import footerLogo1 from "../../assets/images/footer_logo_1.png";
import footerLogo2 from "../../assets/images/footer_logo_2.png";
import footerLogo3 from "../../assets/images/footer_logo_3.png";
import useWindowDimensions from '../hooks/useWindowDimensions';

const Footer = () => {
  const { width } = useWindowDimensions();

  return (
    <footer className="footer text-primary">
      <Container className='footer-box container-sm text-primary text-center pt-5 px-5'>
        <Stack direction='vertical' className="mt-3" gap={2}>
          <Stack direction="horizontal" className='footer-icon'>
            <img src={phoneIcon} alt="Phone icon" /><a href="tel:4034837241" style={{ textDecoration: "none", color: "var(--primary-color)" }}><p>(403) 483 7241</p></a>
          </Stack>
          <Stack direction="horizontal" className='footer-icon'>
            <img src={emailIcon} alt="Email icon" /><a href="mailto:info.mustafavora@gmail.com" style={{ textDecoration: "none", color: "var(--primary-color)" }}><p>info.mustafavora@gmail.com</p></a>
          </Stack>
          <Stack direction="horizontal" className='footer-icon'>
            <img src={addressIcon} alt="Location marker icon" /><a href="http://maps.google.com/?q=144, 1935-32 Avenue NE, Calgary, AB, T2E 7C8" target='blank' style={{ textDecoration: "none", color: "var(--primary-color)" }}><p>144, 1935-32 Avenue NE, Calgary, AB, T2E 7C8</p></a>
          </Stack>
          <Stack direction='horizontal' className='footer-logos my-3' gap={3}>
            <img className="logo-3" src={footerLogo3} alt="Real estate company logo" />
            <img src={footerLogo1} alt="Real estate company logo" />
            <img className="logo-2" src={footerLogo2} alt="Real estate company logo" />
          </Stack>
        </Stack>
        {width < 600 && <hr />}
        <Navbar>
          <Nav className='text-light'>
            <Stack className='footer-nav'>
              <Link className="nav-link" to="/home">Home</Link>
              <Link className="nav-link" to="/services">Services</Link>
              <Link className="nav-link" to="/listings">Listings</Link>
              <Link className="nav-link" to="/about">About</Link>
            </Stack>
          </Nav>
        </Navbar>
      </Container>
      <hr />
      <div className="text-center py-1">
        <p>&copy; Mustafa Vora. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
