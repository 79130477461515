import { useState } from "react";

import { db } from '../db/firestoredb.js';
import { addDoc, collection } from "firebase/firestore";

const useStoreData = (collectionName) => {
    const [ processing, setProcessing ] = useState(false);
    const [ error, setError ] = useState(false);

    const addData = async (data) => {
        setProcessing(true);
        setError(false);

        try {
            const colRef = collection(db, collectionName);
            await addDoc(colRef, data);

        } catch (e) {
            console.log(e);
            setError(true);
        };

        setProcessing(false);
    }

    return { addData, processing, error};
}
 
export default useStoreData;