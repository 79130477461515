import React from 'react';
import Home from "../components/homepage/Home";
import FeaturedListings from '../components/homepage/FeaturedListings';
import TestimonialCarousel from '../components/homepage/TestimonialCarousel';
import About from '../components/homepage/About';
import BuySell from '../components/homepage/BuySell';
import Contact from '../components/homepage/Contact';

const HomePage = () => {
  return (
    <>
      <Home />
      <TestimonialCarousel />
      <BuySell />
      <FeaturedListings />
      <About />
      <Contact />
    </>
  );
};

export default HomePage;
