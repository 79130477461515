import Container from "react-bootstrap/esm/Container";
import Button from "react-bootstrap/esm/Button";
import Image from "react-bootstrap/Image";
import Stack from "react-bootstrap/Stack";

import { useEffect } from "react";
import { useParams } from "react-router-dom";

import title_image from '../../assets/images/title_image.jpg';
import homeIcon from '../../assets/images/home_icon.svg';

import useWindowDimensions from '../hooks/useWindowDimensions';

const Home = () => {

  const { section } = useParams();
  useEffect(() => {
    if (section === 'home') {
      const homeElem = document.getElementById('home');
      homeElem.scrollIntoView(false);
    }

  }, [section]);

    const { width } = useWindowDimensions();

    return ( 
        <div id="home" className="container-fluid">
            <div className="img-overlay">
                <Image src={title_image} className="img-fluid home-img" />
            </div>
            <Container className="home-box container-fluid translate-middle text-center">
                <Stack direction="vertical">
                    <h1>Trusted to turn dreams into addresses...</h1>
                    <h2>BUY. SELL. INVEST.</h2>
                    <Stack direction={width > 700 ? "horizontal" : "vertical"} gap={4} className="cta-box mt-5">
                        <Button className="home-button bg-lighter text-primary" href="https://sso.cirrealty.ca/HomeEstimate/index.html?g=BDD1089E-9488-43BC-AF02-3820615D4E79" target="blank" size="lg" variant="primary">
                          <div>
                            <div><img src={homeIcon} alt="Icon of a home"></img></div>
                            <p>FREE HOME ESTIMATE</p>
                          </div>
                        </Button>{' '}
                        <Button className="home-button bg-lighter text-primary" href="https://sso.cirrealty.ca/HotNewProperties/HotNewProperties.html?g=BDD1089E-9488-43BC-AF02-3820615D4E79" target="blank" size="lg" variant="primary">
                          <div>
                            <div><img src={homeIcon} alt="Icon of a home"></img></div>
                            <p>ACCESS ACTIVE LISTINGS</p>
                          </div>
                        </Button>{' '}
                    </Stack>
                </Stack>
            </Container>
        </div>
     );
}
 
export default Home;