import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import ListingCard from '../listings/ListingCard';
import Container from 'react-bootstrap/esm/Container';
import Slider from "react-slick";

import useWindowDimensions from '../hooks/useWindowDimensions';

import house1 from '../../assets/images/house1.jpg';
import bgElem6 from '../../assets/images/bg-elem-6.svg';


const testData = {
  imgSrc: house1,
  address: "250 Nolanhurst Bay NW, Calgary, AB",
  yearBuilt: 2018,
  size: '2000 sqft' ,
  specs: '3/2/1',
  price: "700k",
  disabled: true,
}


const FeaturedListings = () => {

  const { section } = useParams();
  useEffect(() => {
    if (section === 'listings') {
      const homeElem = document.getElementById('listings');
      homeElem.scrollIntoView(false);
    }

  }, [section]);


  
  const { width } = useWindowDimensions();
  const [ sliderSettings, setSliderSettings ] = useState({
    dots: false,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true
  });

  useEffect(() => {
    if (width <= 1010) {
      setSliderSettings({
        dots: false,
        infinite: true,
        speed: 900,
        slidesToShow: 1,
        slidesToScroll: 1,
        focusOnSelect: true
      });
    } else {
      setSliderSettings({
        dots: false,
        infinite: true,
        speed: 900,
        slidesToShow: 3,
        slidesToScroll: 1,
        focusOnSelect: true
      });
    }
  }, [width]);

  return (
    <div className='bg-elem-container'>
      <img src={bgElem6} alt="graphical element" className='bg-elem bg-elem-6'></img>


    <Container id="listings" className="container-xxl">
      <div className="featured-listings">
        <h2>WHAT'S ON THE MARKET?</h2>
        <Slider {...sliderSettings} className="featured-listings-carousel"> {/*   */}
          <ListingCard data={testData} />
          <ListingCard data={testData} />
          <ListingCard data={testData} />
        </Slider>
      </div>

    </Container>
    </div>
  );
};

export default FeaturedListings;
