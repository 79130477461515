import React, { useState, useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import '../../assets/styles/custom.css';
import nameBrand from '../../assets/images/name_brand.png';

const Header = () => {
  const [opacity, setOpacity] = useState(0);
  const [collapsed, setCollapsed] = useState(true);
  const toggleRef = useRef(null);

  const clickToggle = () => {
    if (!collapsed) toggleRef.current?.click();
  }

  const handleToggleCollapse = () => {
    if (!collapsed) {
      setTimeout(() => {
        setCollapsed(!collapsed);
      }, 200);
    } else {
      setCollapsed(!collapsed);
    }
  };

  useEffect(() => {
    console.log(collapsed);
    if (typeof window !== "undefined") {
      const handleScroll = () => {
        if (collapsed) {
          let newOpacity = window.scrollY / 600;
          newOpacity = Math.min(1, newOpacity);
          setOpacity(newOpacity);
        } else {
          setOpacity(1);
        }
      };

      window.addEventListener("scroll", handleScroll);
    }
    if (collapsed) {
      let newOpacity = window.scrollY / 600;
      newOpacity = Math.min(1, newOpacity);
      setOpacity(newOpacity);
    } else {
      setOpacity(1);
    }

  }, [collapsed]);

  return (
    <Navbar expand="lg" className="header" fixed="top" style={{ backgroundColor: `rgba(133, 162, 181, ${opacity})` }}>
      <Container className="text-light">
        <Link to="/home" className="text-primary nav-link navbar-brand">
          <img src={nameBrand} className="name-brand" alt="Mustafa Vora" />
        </Link>
        <Navbar.Toggle ref={toggleRef} onClick={handleToggleCollapse} aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="text-light">
          <Nav className="ms-auto">
            <Link onClick={clickToggle} className="nav-link" to="/home">Home</Link>
            <Link onClick={clickToggle} className="nav-link" to="/services">Services</Link>
            <Link onClick={clickToggle} className="nav-link" to="/listings">Listings</Link>
            <Link onClick={clickToggle} className="nav-link" to="/about">About</Link>
            <Link onClick={clickToggle} className="nav-link" to="/contact" style={{ fontWeight: 650 }}>Call Now</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
